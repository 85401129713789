module.exports = [{
      plugin: require('../../../node_modules/@vp/om-gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-plugin-rollbar-provider/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"ed26d80633bf4c8b8a69d94ce0f602e5","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production","client":{"javascript":{"code_version":"59c781e","source_map_enabled":true,"guess_uncaught_frames":true}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
